import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="iso3166Select"
export default class iso3166SelectController extends Controller {
  correctionFactor = 2;
  defaultClientHeight = 15;
  static targets = ["country", "metro", "region", "source", "subdivision", "subdivisionType"];
  static values = {
    countriesPath: String,
    metrosPath: String,
    regionsPath: String,
    subdivisionsPath: String,
    subdivisionTypesPath: String
  }

  connect() { this.changePropertySearchURL({type: "connected"}, this.countriesPathValue)}

  async changePropertySearchURL(e, url) {
    if (["change", "connected"].includes(e.type)) {
      // console.log(e.type, url);
      let params = {
        url,
        task_id: document.getElementById("task_id").value,
        selected_source: this.getSelectedValues(this.sourceTarget),
        selected_countries: this.getSelectedValues(this.countryTarget),
        selected_subdivision_types: this.getSelectedValues(this.subdivisionTypeTarget),
        selected_subdivisions: this.getSelectedValues(this.subdivisionTarget),
        selected_metros: this.getSelectedValues(this.metroTarget),
        selected_regions: this.getSelectedValues(this.regionTarget)
      }

      this.sendTurboStream(params);
    }
  }

  async changeCountry(e) {
    this.changePropertySearchURL(e, this.countriesPathValue);
  }

  async changeMetro(e) {
    this.changePropertySearchURL(e, this.metrosPathValue);
  }

  async changeRegion(e) {
    this.changePropertySearchURL(e, this.regionsPathValue);
  }

  // TODO: async changeSource(e) {
  //   this.changePropertySearchURL(e, this.sourcePathValue);
  // }

  async changeSubdivision(e) {
    this.changePropertySearchURL(e, this.subdivisionsPathValue);
  }

  async changeSubdivisionType(e) {
    this.changePropertySearchURL(e, this.subdivisionTypesPathValue);
  }

  // Private
  createQueryString = (obj, key = "json") => {
    return new URLSearchParams([[key, JSON.stringify(obj)]]);
  }

  getSelectedValues = (target) => {
    let {tagName, selectedOptions, value} = target;

    switch (tagName) {
      case "SELECT":
        return Array.from(selectedOptions).map(({value}) => value);
      case "INPUT":
        return [value]
    }
  }

  sendTurboStream = async({url, ...params}) => {
    let urlQueryString = [url, this.createQueryString(params, "property_search_url[json]")].join("?");

    return await get(urlQueryString, { responseKind: "turbo-stream" });
  }

  // responseOptionCount = async (response) => {
  //   let text = await response.responseText;
  //
  //   return text.split("<option").length - 1;
  // }
  //
  // updateTargetHeight = async ({target, response = null}) => {
  //   let optionHeight = target.options[0]?.clientHeight || this.defaultClientHeight;
  //   let optionCount = response ? (await this.responseOptionCount(response)) : target.options.length;
  //   let selectHeight = (optionCount * optionHeight) + this.correctionFactor;
  //
  //   target.style.height = `${selectHeight}px`;
  // }
}

// $(document, "#task_property_search_url_country").on("change", (e) => { console.log("change", e) })
// $(document).on("turbo:load", () => { console.log("turbo:load") })
$("form#new_task, form#edit_task").on("turbo:before-stream-render", (e) => {
  document.getElementById(e.target.target).disabled = e.target.querySelector('template').innerHTML === "";
})

// $(() => {
//   // reference: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
//   let config = {childList: true, characterData: true};
//   let targets = $("select[data-action]");
//   let observer = new MutationObserver((mutations) => {
//     mutations.forEach(function( mutation ) {
//       let [correctionFactor, defaultClientHeight] = [2, 15];
//       let {target} = mutation;
//       let optionHeight = target.options[0]?.clientHeight || defaultClientHeight;
//       let selectHeight = (target.options.length * optionHeight) + correctionFactor;
//
//       target.style.height = `${selectHeight}px`;
//       observer.disconnect();
//     });
//   });
//
//   targets.map((_, target) =>  observer.observe(target, config));
// })